<ng-container>
  <div class="deployCheckCont">
    <div class="checkbox_specAll">
      <input
        type="checkbox"
        class="checkbox"
        id="checkbox_specAll"
        (click)="selectAll()"
      />
      <label for="checkbox_specAll">Select All</label>
    </div>
  </div>
  <div class="deployCont">
    @for (item of schemas(); track item.title; let i = $index) {
    <div class="deployCheckCont">
      <div>
        <input
          type="checkbox"
          class="checkbox"
          [id]="'checkbox_spec'+i"
          [(ngModel)]="item.select"
          (click)="touched(item)"
        />
        <label [for]="'checkbox_spec'+i">{{item.title}} @if (_type == item.type) {
          <span>@if (data.list?.length) {
            <strong>({{data.list?.length}})</strong>
            }</span>
          }</label>
      </div>
    </div>
    }
  </div>
  @if (hugeType=='deploy') {
  <div>
    <textarea
      disabled="true"
      class="deploy_comment sub_title2"
      [ngModel]="deployComment()"
    ></textarea>
  </div>
  }

  <button
    class="action_button btn title5"
    (click)="deploy()"
    [disabled]="isSaved"
  >Deploy</button>

</ng-container>

<!-- [ngModel]="dataItem[column.field]"
disabled=true-->
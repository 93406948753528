// @ts-nocheck
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private readonly searchSubject = new BehaviorSubject<any>(false);
  readonly $SearchApperiance = this.searchSubject.asObservable();

  private readonly searchObj = new BehaviorSubject<any>('');
  readonly $GridHandler = this.searchObj.asObservable();

  private readonly searchCounterSubject = new BehaviorSubject<any>('');
  readonly $CounterProcessing = this.searchCounterSubject.asObservable();

  public searchAmount = {
    current: 0,
    total: 0,
  };

  searchListener(event, flag) {
    this.searchSubject.next({ event, flag });
  }

  foundTestProcessing(text) {
    this.searchObj.next(text);
  }

  searchResult(value) {
    this.searchCounterSubject.next(value);
  }
  //tvbet
  highLight(grid, result) {
    if (result.length) {
      grid.scrollTo({
        row: result[this.searchAmount.current].itemKey,
        column: result[this.searchAmount.current].columnKey,
      });
      const table = grid.ariaRoot.nativeElement.querySelector(
        '.k-grid-content table'
      );
      this.removeCostumClass(grid);
      setTimeout(() => {
        table.querySelectorAll('.k-state-selected').forEach((el) => {
          if (
            el.getAttribute('ng-reflect-row-index') ==
              result[this.searchAmount.current].itemKey &&
            el.getAttribute('ng-reflect-column-index') ==
              result[this.searchAmount.current].columnKey
          ) {
            el.classList.add('my-class');
          }
        });
      }, 1);
    }
  }

  removeCostumClass(grid) {
    if (grid) {
      const table = grid.ariaRoot.nativeElement.querySelector(
        '.k-grid-content table'
      );
      for (let i = 0; i < table.rows.length; i++) {
        for (let j = 0; j < table.rows[i].cells.length; j++) {
          table.rows[i].cells[j].classList.remove('my-class');
        }
      }
    }
  }

  foundDataResult(event, data, columns, grid) {
    let result = [];
    if (data?.['en']?.length) {
      for (let i = 0; i < data['en'].length; i++) {
        for (let y = 0; y < columns.length; y++) {
          const element = data['en'][i][columns[y].field];
          if (
            element
              .toString()
              .toLowerCase()
              .trim()
              .indexOf(event.toLowerCase().trim()) != -1 &&
            element.toString().toLowerCase().trim().indexOf('<img')
          ) {
            result.push({ itemKey: i, columnKey: y + 2 });
          }
        }
      }
    }

    return result;
  }

  clean() {
    this.searchObj.next('');
  }

  checkRowHeight(grid) {
    // if(grid){
    //   return grid.ariaRoot.nativeElement.querySelector('.k-grid-content table').querySelector("td").offsetHeight
    // }
    return 78;
  }
}
